import React from "react";
import "./styles.css";

import cta from "../../assets/call_whatsapp.png";

const ctaWpp = () => {
  return (
    <figure id="ctaWpp">
      <a
        href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
        target="_blank"
        rel="noreferrer"
      >
        <img src={cta} alt="Chamada de ação para Whatsapp" />
      </a>
    </figure>
  );
};

export default ctaWpp;
