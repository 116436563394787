import { InstagramEmbed } from "react-social-media-embed";
import "./styles.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CTAWpp from "../../components/CTAWpp";
import CTATel from "../../components/CTATel";

import bullet from "../../assets/bullet.png";
import AVTR1 from "../../assets/about1.png";
import AVTR2 from "../../assets/about1-2.png";
import call_whats from "../../assets/call_whats.png";
import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";
import card4 from "../../assets/card4.png";
import card_service1 from "../../assets/card_service1.png";
import card_service2 from "../../assets/card_service2.png";
import card_service3 from "../../assets/card_service3.png";
import card_service4 from "../../assets/card_service4.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: AVTR1,
  },
  {
    id: 2,
    image: AVTR2,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <div className="top">
              <figure className="design">
                <img src={bullet} alt="Bullet Point" />
              </figure>

              <h2 className="title">Textura projetada</h2>
            </div>

            <p className="text">
              A textura projetada é um revestimento feito de uma maneira bem
              diferente – que distingue essa textura das outras. A aplicação
              desta textura é feita através de pistolas de alta pressão, o que
              permite resultados estéticos muito bonitos além de oferecer também
              uma alta produtividade durante a aplicação.
            </p>

            <p className="cta">
              Solicite um orçamento via WhatsApp ou telefone:
            </p>

            <div className="bottom">
              <CTAWpp />
              <CTATel />
            </div>
          </div>

          <div className="right">
            <div className="top">
              <h2 className="title">Textura projetada</h2>

              <figure className="design">
                <img src={bullet} alt="Bullet Point" />
              </figure>
            </div>

            <Swiper
              className="carousel"
              spaceBetween={0}
              slidesPerView={1}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id}>
                    <div className="banner">
                      <figure>
                        <img src={image} alt={`Exemplo ${id} de Local`} />
                      </figure>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="top">
            <figure className="design">
              <img src={bullet} alt="Bullet Point" />
            </figure>

            <h2 className="title">Conheça nosso serviços</h2>
          </div>

          <p className="text">
            Sempre buscamos entregar um serviço com qualidade e agilidade. Com o
            foco em conquistar a satisfação dos nossos clientes, prestamos um
            serviço altamente profissional e com acabamentos impecáveis!
          </p>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={card1} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>

            <div className="item">
              <figure>
                <img src={card2} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>

            <div className="item">
              <figure>
                <img src={card3} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>

            <div className="item">
              <figure>
                <img src={card4} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="top">
            <figure className="design">
              <img src={bullet} alt="Bullet Point" />
            </figure>

            <h2 className="title">Trabalhos realizados</h2>
          </div>

          <p className="text">
            Sempre buscamos entregar um serviço com qualidade e agilidade. Com o
            foco em conquistar a satisfação dos nossos clientes, prestamos um
            serviço altamente profissional e com acabamentos impecáveis!
          </p>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={card_service1} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>

            <div className="item">
              <figure>
                <img src={card_service2} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>

            <div className="item">
              <figure>
                <img src={card_service3} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>

            <div className="item">
              <figure>
                <img src={card_service4} alt="Textura Projetada" />
              </figure>

              <figure className="cta">
                <a
                  href="http://wa.me/+5511995495773?text=Gostaria%20de%20saber%20mais"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={call_whats} alt="Chamada de ação para Whatsapp" />
                </a>
              </figure>
            </div>
          </div>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <div className="top">
            <figure className="design">
              <img src={bullet} alt="Bullet Point" />
            </figure>

            <h2 className="title">Veja mais resultados em nosso Instagram</h2>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/CoOSdFPIt9l/"
              width={600}
            />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
