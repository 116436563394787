import React from "react";
import "./styles.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CTAWpp from "../../components/CTAWpp";
import CTATel from "../../components/CTATel";

import AVTR1 from "../../assets/BANNER_TOPO.png";
import AVTR1Mobile from "../../assets/mobile/BANNER_TOPO.png";
import AVTR2 from "../../assets/BANNER_TOPO2.png";
import AVTR2Mobile from "../../assets/mobile/BANNER_TOPO2.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: AVTR1,
  },
  {
    id: 2,
    image: AVTR2,
  },
];

const dataMobile = [
  {
    id: 1,
    image: AVTR1Mobile,
  },
  {
    id: 2,
    image: AVTR2Mobile,
  },
];

const Header = () => {
  return (
    <header id="header">
      <Swiper
        className="carousel"
        spaceBetween={0}
        slidesPerView={1}
        navigation
        loop
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? dataMobile.map(({ id, image }) => {
              return (
                <SwiperSlide key={id}>
                  <figure className="banner">
                    <img src={image} alt={`Exemplo ${id} de Local`} />
                  </figure>
                </SwiperSlide>
              );
            })
          : data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id}>
                  <figure className="banner">
                    <img src={image} alt={`Exemplo ${id} de Local`} />
                  </figure>
                </SwiperSlide>
              );
            })}
      </Swiper>

      <article className="container">
        <h1 className="title">
          Somos especializados em <span>revestimento</span>{" "}
        </h1>

        <p className="cta">Solicite um orçamento via WhatsApp ou telefone:</p>

        <div className="bottom">
          <CTAWpp />
          <CTATel />
        </div>
      </article>
    </header>
  );
};

export default Header;
