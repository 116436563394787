import React from "react";
import "./styles.css";

import CTAWpp from "../../components/CTAWpp";
import CTATel from "../../components/CTATel";

import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";

const Content = () => {
  return (
    <section id="content">
      <div className="container">
        <div className="gallery">
          <figure>
            <img src={icon1} alt="Venha até nós" />
          </figure>

          <figure>
            <img src={icon2} alt="Funcionamento" />
          </figure>

          <figure>
            <img src={icon3} alt="Orçamentos" />
          </figure>
        </div>

        <p className="cta">Solicite um orçamento via WhatsApp ou telefone:</p>

        <div className="bottom">
          <CTAWpp />
          <CTATel />
        </div>
      </div>
    </section>
  );
};

export default Content;
